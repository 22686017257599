export default function ProAbout() {
    return(
        <>
         <section id="about" class="about" data-aos="fade-up" data-aos-duration="1000">
      <div class="container">

        <div class="section-title">
          <h2 >Sobre </h2>
          <p>Bem-vindo à <b>PromovaOnline</b>, maior agência de marketing digital de Brasília - DF dedicada desde 2019 a transformar ideias em resultados tangíveis. Com uma abordagem inovadora e estratégias personalizadas, estamos comprometidos em impulsionar a visibilidade da sua marca, atrair seu público-alvo e alcançar metas de negócios excepcionais.</p>
        </div>

        <div class="row content">
          <div class="col-lg-6">
            <p>
            O que nos diferencia é a nossa paixão por criar soluções que realmente importam. Nossa equipe experiente combina criatividade e conhecimento técnico para oferecer uma gama completa de serviços, desde:
            </p>
            <ul className="fs-4 fw-bold">
              <li><i class="ri-check-double-line"></i> Estratégias de marketing digital </li>
              <li><i class="ri-check-double-line"></i> Gestão de redes sociais </li>
              <li><i class="ri-check-double-line"></i> Desenvolvimento de sites </li>
              <li><i class="ri-check-double-line"></i> Automação de Vendas.</li>
            </ul>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0">
            <p>
            Na <b>PromovaOnline</b>, não apenas entendemos o mundo do marketing digital, mas também compreendemos as necessidades únicas do seu negócio. Estamos comprometidos em construir parcerias sólidas e ajudar sua marca a se destacar em um ambiente digital em constante evolução a "Era da Internet".
            </p>
            <a href="https://api.whatsapp.com/send/?phone=5561992387326&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+de+mais+informações+sobre+o+serviço+de+vocês+%3F(site)&type=phone_number&app_absent=0" class="btn-learn-more">Falar com o Especialista</a>
          </div>
        </div>

      </div>
    </section>
        
        </>
    )
}