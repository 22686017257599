import './styleCompetencias.css'
export default function Competencias(){
    return(
        <>
        <section id="competencias" className='container'>
        <div class="section-title p-3">
                        <h2>5 Competências pelo preço de 1</h2>
                        <p>Terceirize todo seu marketing com a Promova, dessa forma você tem cinco profissionais pelo preço de um trabalhando pra você, sem se preocupar com contratação, gestão desse time, riscos trabalhistas e outras burocracias. </p>
                    </div>
        <img className="competencias" src="https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/PromovaOnline/competencias-promova,.png?t=2024-03-25T13%3A33%3A10.805Z"/>
                   <div className='text-center'>
                   <a className="btn btn-lg btn-warning fw-bold fs-2 rounded-pill" src="https://api.whatsapp.com/send/?phone=5561992387326&text=Ol%C3%A1+%2APromova+Online%2A%21+Gostaria+de+mais+informações+sobre+o+serviço+de+vocês+%3F(site)&type=phone_number&app_absent=0">Preciso de um time assim</a>

                   </div>
        </section>
        </>
    )
}